@import '@socialbrothers/assets/styles/styles.scss';

.Wrapper {
  width: 100%;
  overflow-x: auto;
}

.BaseTable {
  &__InfoIcon {
    margin-right: px(1);
  }

  &__HeadingCell {
    display: flex;
    align-items: center;
  }

  &__Row {
    &--Clickable {
      cursor: pointer;
    }
  }
}

.TableHeader {
  display: flex;
  align-items: flex-start;
  margin-bottom: $default-margin;

  &__Search {
    width: 100%;
    margin-right: $default-margin;
  }

  &__Add {
    margin-left: auto;
  }
}

.TableBody {
  position: relative;
  border-radius: $border-radius;

  &--WithTableActions {
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    table {
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
    }
  }

  &__Actions {
    visibility: hidden;
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    left: 0;
    opacity: 0;

    @include transition(all, sm);

    &--Visible {
      visibility: visible;
      transform: translate3d(0, -100%, 0);
      opacity: 1 !important;
    }
  }

  &__TableWrapper {
    position: relative;
    z-index: 1;
  }

  .Sortable {
    display: flex;
    cursor: pointer;

    &__Icon {
      margin-left: auto;
      padding-left: px(2);
    }
  }

  .Empty {
    padding-top: px(2);
    padding-bottom: px(2);
    font-weight: $font-weight-bold;
    text-align: center;
  }

  table {
    width: 100%;
    border-radius: $border-radius;

    thead,
    tbody {
      tr {
        td,
        th {
          padding: px(0.5) px(1.5);
          text-align: left;
        }

        th {
          padding-top: px(1);
          padding-bottom: px(1);
          white-space: nowrap;
          user-select: none;
        }
      }
    }

    thead {
      tr {
        border: 1px solid color(black, dark);
        border-bottom: 0;

        th {
          position: sticky;
          z-index: 10;

          top: 0;
          background-color: color(black);
          color: color(white);

          &:hover {
            .Tooltip {
              display: block;
            }
          }

          .Tooltip {
            display: none;

            position: absolute;
            top: 110%;
            left: 10px;

            &--RTL {
              right: 10px;
              left: auto;
            }
          }

          &:last-child {
            border-right: 0;
          }
        }
      }
    }

    tbody {
      tr {
        border: 1px solid color(gray, 200);
        border-top: 0;
        background-color: color(gray, 100);

        &:nth-child(odd) {
          background-color: color(white);
        }

        &:hover {
          td {
            background-color: color(gray, 200);
          }
        }
      }

      td {
        border-right: 1px solid color(gray, 200);
        white-space: nowrap;
      }
    }
  }
}

.TableFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: $default-margin;

  .PerPage {
    display: flex;
    align-items: center;
    white-space: nowrap;

    select {
      margin: 0 px(1);
      padding: px(1);
      text-align: center;
    }

    &__Current {
      margin-left: px(3);
    }
  }

  .Results {
    margin-left: px(2);
    opacity: 0.5;
  }

  .Pagination {
    margin-left: auto;
  }

  @include media-breakpoint-only(xs) {
    flex-direction: column-reverse;
    align-items: flex-start;

    .Pagination {
      margin-bottom: $default-margin;
    }
  }
}

.TableActions {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: px(1);
  border-radius: $border-radius $border-radius 0 0;
  background-color: color(secondary);
  color: color(white);

  &__ActionsWrapper {
    display: flex;
  }

  &__Action {
    margin: 0 px(1);

    &:last-child {
      margin-right: 0;
    }

    &:first-child {
      margin-left: 0;
    }
  }
}

.Action {
  color: color(white);
  cursor: pointer;

  &__Icon {
    margin-right: px(0.5);
  }
}
