@import '@socialbrothers/assets/styles/abstracts';

.Field {
  position: relative;
  flex: 1 1;
  margin-bottom: px(3);
  background-color: color(white);

  &--Error {
    input,
    textarea,
    select,
    > div,
    .Field__Fieldset {
      border-color: color(danger);
    }

    .Field__Label {
      color: color(danger);
    }
  }

  &__Fieldset {
    margin: 0;
    padding: 0;

    input[type='text'],
    input[type='file'],
    input[type='email'],
    input[type='number'],
    input[type='password'],
    select,
    textarea,
    > div {
      border: none;
      background-color: transparent !important;
    }
  }

  &__Error,
  &__Description {
    margin-top: px(0.5);
    padding-left: px(2);
    font-size: $font-size-xs;
  }

  &__Error {
    color: color(danger);
  }

  &__Children {
    display: flex;
  }

  &__Prefix,
  &__Suffix {
    display: flex;
    align-items: center;
    padding: 0 px(2);
    background-color: $input-border-color;
    white-space: nowrap;
  }

  &__Prefix {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  &__Suffix {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  &--Prefix {
    .Field__Label {
      margin-left: 54px;
    }
  }
}
