@import '@socialbrothers/assets/styles/abstracts';

.BaseForm {
	&__Error {
		color: color(danger);
		font-size: $font-size-sm;

		&--withSubmit {
			margin-top: px(2);
		}
	}
}
