@import '@socialbrothers/assets/styles/abstracts';

.LoginForm {
	.Footer {
		text-align: center;
		padding-top: px(2);
		margin-top: px(3.25);
		border-top: 1px solid color(gray, 200);

		a {
			display: block;
			color: color(primary);
		}
	}
}
