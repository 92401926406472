@import '@socialbrothers/assets/styles/abstracts';

.Overlay {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(color(black), 0.6);
}

.Wrapper {
  position: fixed;
  z-index: 1000;
  top: 0;
  width: 100%;
}

.Modal {
  display: flex;
  overflow: auto;
  flex-direction: column;
  width: calc(100% - 30px);
  max-width: 500px;
  max-height: calc(100vh - 30px);
  margin: 15px auto;
  border-radius: $border-radius;
  background-color: color(white);
  box-shadow: $box-shadow;
}

.Header {
  display: flex;
  align-items: center;
  padding: px(2);
  border-bottom: 1px solid #e3e6f0;
}

.Close {
  margin-left: auto;
  cursor: pointer;

  &:hover {
    color: color(black);
  }
}

.Body {
  padding: px(2);

  hr {
    width: auto;
    height: 1px;
    margin-top: px(2);
    margin-right: px(-2);
    margin-bottom: px(2);
    margin-left: px(-2);
    border: 0;
    background-color: #e3e6f0;
  }
}
