@import '@socialbrothers/assets/styles/abstracts';

.Questions {
	&__Item {
		margin-bottom: px(1);
	}
}

.Question {
	border-radius: $border-radius;
	border: 1px solid color(gray, 200);
	border-left: 4px solid color(secondary);
	background-color: color(white);

	&__Header {
		display: flex;
		align-items: center;

		padding: px(1) px(1.5);
	}

	&__Title {
		margin-right: px(2);
		margin-bottom: 0;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		
		small {
			font-weight: $font-weight-regular;
		}
	}

	&__Answers {
		border-top: 1px solid color(gray, 200);
		padding: px(1) px(1.5);
	}

	&__Buttons {
		margin-left: auto;
		white-space: nowrap;
	}
}
