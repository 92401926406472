@import '@socialbrothers/assets/styles/abstracts';

.Answers {
	margin-bottom: px(1);

	&--None {
		margin-bottom: px(1);
		font-weight: $font-weight-bold;
	}
}

.Answer {
	&__Header {
		display: flex;
		align-items: center;

		&:hover {
		  background-color: color(gray, 200);
		  margin: 0 px(-1.5);
		  padding: 0 px(1.5);
		}
	}

	&__Title {
		margin: 0 px(2) 0 0;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	&__Buttons {
		margin-left: auto;
		white-space: nowrap;
	}
}
