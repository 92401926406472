@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 900;
  src: url('Source_Sans_Pro/SourceSansPro-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 900;
  src: url('Source_Sans_Pro/SourceSansPro-BlackItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  src: url('Source_Sans_Pro/SourceSansPro-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 700;
  src: url('Source_Sans_Pro/SourceSansPro-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: url('Source_Sans_Pro/SourceSansPro-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 600;
  src: url('Source_Sans_Pro/SourceSansPro-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: url('Source_Sans_Pro/SourceSansPro-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 400;
  src: url('Source_Sans_Pro/SourceSansPro-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  src: url('Source_Sans_Pro/SourceSansPro-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 300;
  src: url('Source_Sans_Pro/SourceSansPro-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 200;
  src: url('Source_Sans_Pro/SourceSansPro-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 200;
  src: url('Source_Sans_Pro/SourceSansPro-ExtraLightItalic.ttf') format('truetype');
}
