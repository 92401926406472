@import '@socialbrothers/assets/styles/abstracts';

.AuthWrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
	background-color: color(primary);
	background-image: linear-gradient(180deg, color(black) 10%, color(black, dark) 100%);

	.Body {
		width: 100%;
		min-height: 400px;
		padding: px(4) px(6);
	}

	.Image {
		display: block;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.Wrapper {
		display: flex;
		flex-direction: row;
		max-width: 800px;
		margin: 0 auto;
		border-radius: $border-radius;
		overflow: hidden;

		@include media-breakpoint-down(sm) {
			flex-direction: column;
		}

		.Column {
			display: flex;
			position: relative;
			width: 50%;

			@include media-breakpoint-down(sm) {
				width: 100%;
			}
		}
	}
}
