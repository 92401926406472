@import '@socialbrothers/assets/styles/abstracts';

.Sections {
	&--None {
		margin-bottom: px(2);
		font-weight: $font-weight-bold;
	}

	&__Item {
		margin-bottom: px(2);
	}
}

.Section {
	border-radius: $border-radius;
	border: 1px solid color(gray, 300);
	background-color: color(gray, 100);

	&__Header {
		display: flex;
		align-items: center;

		padding: px(1) px(3) px(1) px(1.5);
	}

	&__Title {
		margin-right: px(2);
		margin-bottom: 0;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	&__Body {
		position: relative;
		padding-left: px(3);
		padding-right: px(3);

		&::after {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: px(1.5);
			bottom: 0;
			border-left: 1px dashed color(gray, 300);
		}
	}

	&__EmptySection {
		font-weight: $font-weight-bold;
		margin-bottom: px(1.5);
	}

	&__Footer {
		padding: 0 px(1.5) px(2) px(1.5);
	}

	&__Buttons {
		margin-left: auto;
		white-space: nowrap;
	}

	&--Empty {
		.Section__Body {
			padding-left: px(1.5);

			&::after {
				display: none;
			}
		}
	}
}
