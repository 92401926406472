@import '@socialbrothers/assets/styles/abstracts';

.DropdownButton {
	position: relative;

	&__Button {
		display: flex;
		align-items: center;
		cursor: pointer;
	}

	&__Chevron {
		margin-left: px(1);
		font-size: $font-size-xs;
	}

	&__Dropdown {
		position: absolute;
		border: 1px solid color(gray, 300);
		z-index: 1;
		top: 100%;
		margin-top: 14px;
		right: 0;
		width: 200px;
		border-radius: $border-radius;
		background-color: color(white);
		box-shadow: $box-shadow-overlay;
	}

	&__Item {
		display: block;
		cursor: pointer;
		border-bottom: solid 1px color(gray, 300);
		padding: px(1) 15px;
		user-select: none;
		color: $body-color;

		&:hover {
			text-decoration: underline;
		}
	}
}
